import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-midnight bottom-10 w-full mt-10 mb-10">
      <div className="md:flex items-center justify-between">
        <div id="footer-logo" className="flex items-center">
          <Link to="/">
            <img
              src="/src/assets/SAGELABS_logo_square.svg"
              alt="SageLabs logo"
              className="h-8 mr-2 rounded-full"
            />
          </Link>
          <Link to="/">
            <span className="font-semibold">
              SageLabs delivers AI solutions
            </span>
          </Link>
        </div>
        <div
          id="footer-links"
          className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-12"
        >
          <div>
            {/* <h2 className="uppercase">Resources</h2>
            <p>Blog</p>
            <p>AI tools</p>
            <p>E-Book</p> */}
          </div>
          <div>
            {/* <h2 className="uppercase">Social</h2>
            <a
              href="https://www.instagram.com/sagelabsai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
            <p>X/Twitter</p> */}
          </div>
          <div>
            <h2 className="uppercase">Company</h2>
            <p>
              <Link to="/terms">Terms</Link>
            </p>
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            {/* <p>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </p> */}
          </div>
        </div>
      </div>
      <div className="text-midnight mt-10 text-sm">
        <p>© 2024 SageLabs Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
