import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-[67%_33%] mx-auto">
        <div className="bg-white rounded-lg md:rounded-r-none shadow-lg p-5">
          <h1 className="text-5xl xl:text-6xl mb-8 capitalize">
            Conversational AI solutions
          </h1>
          <h2 className="mb-4 text-xl">
            SageLabs uses expertise in software engineering, machine learning,
            and linguistics to deliver conversational AI solutions.
          </h2>
          <Link to="contact">
            <h3 className="text-gold text-xl underline font-semibold">
              Let's talk
            </h3>
          </Link>
        </div>
        <div className="hidden md:block rounded-r-lg shadow-lg bg-[url('/src/assets/ai_illustration.svg')] bg-midnight bg-cover"></div>
      </div>
    </>
  );
};

export default Hero;
