import ContactForm from "../components/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - SageLabs | Conversational AI Solutions</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1 className="text-3xl md:text-4xl mb-4 font-bold text-center">
          Contact Us
        </h1>
        <p className="my-4 text-md md:text-lg text-center">
          Get in touch to learn more about our solutions
        </p>
        <div className="mt-10 mx-auto max-w-xl bg-white rounded-lg p-6 shadow-md">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
