"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";

import axios from "axios";

import { useState } from "react";

// Define form schema
const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Invalid email address.",
  }),
  message: z
    .string()
    .min(20, {
      message: "Message must be at least 20 characters.",
    })
    .max(500, {
      message: "Message must be less than 500 characters.",
    }),
});

const ContactForm = () => {
  // get environment variable
  const BACKEND_API_KEY = import.meta.env.VITE_BACKEND_API_KEY;
  const API_ENDPOINT_CONTACT_FORM = import.meta.env
    .VITE_API_ENDPOINT_CONTACT_FORM;

  // Define form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  // Define loading state
  const [isLoading, setIsLoading] = useState(false);

  // Define submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true); // Set loading state to true
    // Send the form data to the backend
    axios
      .post(API_ENDPOINT_CONTACT_FORM, values, {
        headers: {
          "X-API-Key": BACKEND_API_KEY,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        // Reset the form values
        form.reset();
        // Show success message
        toast({
          title: "Success",
          description:
            "Thank you for your message. We will be in touch shortly.",
          variant: "default",
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        let errorMessage =
          "An error occurred while submitting the form. Please try again later.";

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            errorMessage =
              "Invalid form data. Please check your inputs and try again.";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized. Please check your API key.";
          } else if (error.response.status === 429) {
            errorMessage = "Too many requests. Please try again later.";
          } else if (error.response.status >= 500) {
            errorMessage = "Server error. Please try again later.";
          }
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage =
            "No response from server. Please check your internet connection and try again.";
        }

        // Show error message
        toast({
          title: "Error",
          description: errorMessage,
          variant: "destructive",
        });
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false
      });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {/* form field for name */}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* form field for email */}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* form field for message */}
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Message</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Message"
                  className="resize-y"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="bg-gold hover:bg-gold/90 h-11 rounded-md px-8"
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </Form>
  );
};

export default ContactForm;
