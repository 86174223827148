import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full text-center">
        <h1 className="text-6xl font-bold text-midnight mb-4">404</h1>
        <h2 className="text-3xl font-semibold text-midnight mb-6">Oh no!</h2>
        <p className="text-gray-600 mb-8">
          It appears that the page you are seeking cannot be located at the
          moment.
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Link to="/">
            <button className="w-full sm:w-auto bg-midnight hover:bg-midnight-300 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
              Go Home
            </button>
          </Link>
          <Link to="/contact">
            <button className="w-full sm:w-auto bg-white hover:bg-gray-100 text-midnight font-bold py-2 px-6 rounded-lg border-2 border-midnight transition duration-300 ease-in-out transform hover:scale-105">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
