import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  return (
    <header className="flex items-center justify-between sticky top-10 mt-10 mb-12 z-50 text-midnight">
      <NavLink to="/" className="flex items-center">
        <img
          src="/src/assets/SAGELABS_logo_square.svg"
          alt="SageLabs logo"
          className="h-12 mr-2 rounded-full"
        />
        <span className="text-xl font-bold">SageLabs</span>
      </NavLink>
      <nav className="hidden md:flex">
        <ul className="flex">
          {/* <li className="ml-12">
            <NavLink
              to="/about"
              className={location.pathname === "/about" ? "font-semibold" : ""}
            >
              About
            </NavLink>
          </li> */}
          {/* <li className="ml-12">
            <NavLink
              to="/blog"
              className={location.pathname === "/blog" ? "font-semibold" : ""}
            >
              Blog
            </NavLink>
          </li> */}
          <li className="ml-12">
            <NavLink
              to="/contact"
              className={
                location.pathname === "/contact" ? "font-semibold" : ""
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
      <button className="md:hidden" onClick={() => setShowMenu(!showMenu)}>
        ☰
      </button>
      {showMenu && (
        <nav className="md:hidden">
          <ul>
            {/* <li>
              <NavLink to="/about">About</NavLink>
            </li> */}
            {/* <li>
              <NavLink to="/blog">Blog</NavLink>
            </li> */}
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;
