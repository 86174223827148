import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - SageLabs | Conversational AI Solutions</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <main aria-labelledby="privacy-policy-title">
        <article className="text-sm pb-8">
          <h1 className="text-4xl mb-5">Privacy Policy</h1>
          <p className="font-semibold my-4">Last updated: June 25, 2024</p>
          <section aria-labelledby="introduction">
            <p className="my-4">
              This privacy notice for SageLabs Ltd (<strong>we</strong>,{" "}
              <strong>us</strong>, or <strong>our</strong>) describes how and
              why we might collect, store, use, and/or share (
              <strong>process</strong>) your information when you use our
              services (<strong>Services</strong>), such as when you:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Visit our website at https://www.sagelabs.tech, or any website
                of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
          </section>
          <section aria-labelledby="questions-concerns">
            <p className="my-4">
              <span className="font-bold">Questions or concerns?</span> Reading
              this privacy notice will help you understand your privacy rights
              and choices. If you do not agree with our policies and practices,
              please do not use our Services. If you still have any questions or
              concerns, please contact us at hello@sagelabs.tech.
            </p>
          </section>
          <section aria-labelledby="summary-key-points">
            <h2 className="text-xl mt-8">SUMMARY OF KEY POINTS</h2>
            <p className="my-4 font-bold italic">
              This summary provides key points from our privacy notice.
            </p>
            <p className="my-4">
              <span className="font-bold">
                What personal information do we process
              </span>{" "}
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use.
            </p>
            <p className="my-4">
              <span className="font-bold">
                Do we process any sensitive personal information?
              </span>{" "}
              We do not process sensitive personal information.
            </p>
            <p className="my-4">
              <span className="font-bold">
                Do we receive any information from third parties?
              </span>{" "}
              We do not receive any information from third parties.
            </p>
            <p className="my-4">
              <span className="font-bold">
                How do we process your information?
              </span>{" "}
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so.
            </p>
            <p className="my-4">
              <span className="font-bold">
                In what situations and with which types of parties do we share
                personal information?
              </span>{" "}
              We may share information in specific situations and with specific
              categories of third parties.
            </p>
            <p className="my-4">
              <span className="font-bold">
                How do we keep your information safe?
              </span>{" "}
              We have organisational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
            </p>
            <p className="my-4">
              <span className="font-bold">What are your rights?</span> Depending
              on where you are located geographically, the applicable privacy
              law may mean you have certain rights regarding your personal
              information.
            </p>
            <p className="my-4">
              <span className="font-bold">
                How do you exercise your rights?
              </span>{" "}
              The easiest way to exercise your rights is by emailing
              hello@sagelabs.tech. We will consider and act upon any request in
              accordance with applicable data protection laws.
            </p>
          </section>
          <section aria-labelledby="what-information-do-we-collect">
            <h2 className="text-xl mt-8">1. WHAT INFORMATION DO WE COLLECT?</h2>
            <h3 className="text-lg my-4">
              Personal information you disclose to us
            </h3>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We collect personal
              information that you provide to us.
            </p>
            <p className="my-4">
              We collect personal information that you voluntarily provide to us
              when you express an interest in obtaining information about us or
              our products and Services, when you participate in activities on
              the Services, or otherwise when you contact us.
            </p>
            <p className="my-4">
              <span className="font-bold">
                Personal Information Provided by You.
              </span>{" "}
              The personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <ul className="list-disc list-inside">
              <li>names</li>
              <li>phone numbers</li>
              <li>email addresses</li>
              <li>mailing addresses</li>
              <li>job titles</li>
              <li>contact preferences</li>
              <li>billing addresses</li>
              <li>contact or authentication data</li>
            </ul>
            <p className="my-4">
              <span className="font-bold">Sensitive Information.</span> We do
              not process sensitive information.
            </p>
            <p className="my-4">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <h3 className="text-lg my-4">
              Information automatically collected
            </h3>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> Some information —
              such as your Internet Protocol (IP) address and/or browser and
              device characteristics — is collected automatically when you visit
              our Services.
            </p>
            <p className="my-4">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p className="my-4">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p className="my-4">The information we collect includes:</p>
            <ul className="list-disc list-inside">
              <li>
                <i>Log and Usage Data.</i> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called 'crash dumps'), and hardware
                settings).
              </li>
              <li>
                <i>Device Data.</i> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
              <li>
                <i>Location Data.</i> We collect location data such as
                information about your device's location, which can be either
                precise or imprecise. How much information we collect depends on
                the type and settings of the device you use to access the
                Services. For example, we may use GPS and other technologies to
                collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </ul>
          </section>
          <section aria-labelledby="how-do-we-process-your-information">
            <h2 className="text-xl mt-8">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent.
            </p>
            <p className="my-4">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </p>
            <ul className="list-disc list-inside">
              <li>
                To deliver and facilitate delivery of services to the user. We
                may process your information to provide you with the requested
                service.
              </li>
              <li>
                To respond to user inquiries/offer support to users. We may
                process your information to respond to your inquiries and solve
                any potential issues you might have with the requested service.
              </li>
              <li>
                To send administrative information to you. We may process your
                information to send you details about our products and services,
                changes to our terms and policies, and other similar
                information.
              </li>
              <li>
                To fulfil and manage your orders. We may process your
                information to fulfil and manage your orders, payments, returns,
                and exchanges made through the Services.
              </li>
              <li>
                To enable user-to-user communications. We may process your
                information if you choose to use any of our offerings that allow
                for communication with another user.
              </li>
              <li>
                To request feedback. We may process your information when
                necessary to request feedback and to contact you about your use
                of our Services.
              </li>
              <li>
                To send you marketing and promotional communications. We may
                process the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. You can opt out of our marketing emails at any
                time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?'
                below.
              </li>
              <li>
                To deliver targeted advertising to you. We may process your
                information to develop and display personalised content and
                advertising tailored to your interests, location, and more.
              </li>
              <li>
                To protect our Services. We may process your information as part
                of our efforts to keep our Services safe and secure, including
                fraud monitoring and prevention.
              </li>
              <li>
                To identify usage trends. We may process information about how
                you use our Services to better understand how they are being
                used so we can improve them.
              </li>
              <li>
                To determine the effectiveness of our marketing and promotional
                campaigns. We may process your information to better understand
                how to provide marketing and promotional campaigns that are most
                relevant to you.
              </li>
              <li>
                To save or protect an individual's vital interest. We may
                process your information when necessary to save or protect an
                individual’s vital interest, such as to prevent harm.
              </li>
            </ul>
          </section>
          <section aria-labelledby="what-legal-bases-do-we-rely-on-to-process-your-information">
            <h2 className="text-xl mt-8">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We only process your
              personal information when we believe it is necessary and we have a
              valid legal reason (i.e. legal basis) to do so under applicable
              law, like with your consent, to comply with laws, to provide you
              with services to enter into or fulfil our contractual obligations,
              to protect your rights, or to fulfil our legitimate business
              interests.
            </p>
            <p className="italic underline my-4">
              If you are located in the EU or UK, this section applies to you.
            </p>
            <p className="my-4">
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Consent. We may process your information if you have given us
                permission (i.e. consent) to use your personal information for a
                specific purpose. You can withdraw your consent at any time.
              </li>
              <li>
                Performance of a Contract. We may process your personal
                information when we believe it is necessary to fulfil our
                contractual obligations to you, including providing our Services
                or at your request prior to entering into a contract with you.
              </li>
              <li>
                Legitimate Interests. We may process your information when we
                believe it is reasonably necessary to achieve our legitimate
                business interests and those interests do not outweigh your
                interests and fundamental rights and freedoms. For example, we
                may process your personal information for some of the purposes
                described in order to:
                <ul className="ml-5 list-disc list-inside">
                  <li>
                    Send users information about special offers and discounts on
                    our products and services
                  </li>
                  <li>
                    Develop and display personalised and relevant advertising
                    content for our users
                  </li>
                  <li>
                    Analyse how our Services are used so we can improve them to
                    engage and retain users
                  </li>
                  <li>Support our marketing activities</li>
                  <li>
                    Diagnose problems and/or prevent fraudulent activities
                  </li>
                  <li>
                    Understand how our users use our products and services so we
                    can improve user experience
                  </li>
                </ul>
              </li>
              <li>
                Legal Obligations. We may process your information where we
                believe it is necessary for compliance with our legal
                obligations, such as to cooperate with a law enforcement body or
                regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we
                are involved.
              </li>
              <li>
                Vital Interests. We may process your information where we
                believe it is necessary to protect your vital interests or the
                vital interests of a third party, such as situations involving
                potential threats to the safety of any person.
              </li>
            </ul>
            <p className="italic underline my-4">
              If you are located in Canada, this section applies to you.
            </p>
            <p className="my-4">
              We may process your information if you have given us specific
              permission (i.e. express consent) to use your personal information
              for a specific purpose, or in situations where your permission can
              be inferred (i.e. implied consent). You can withdraw your consent
              at any time.
            </p>
            <p className="my-4">
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul className="list-disc list-inside">
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>
                For business transactions provided certain conditions are met
              </li>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
          </section>
          <section aria-labelledby="when-and-with-whom-do-we-share-your-personal-information">
            <h2 className="text-xl mt-8">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We may share
              information in specific situations described in this section
              and/or with the following categories of third parties.
            </p>
            <p className="my-4">
              <span className="font-bold">
                Vendors, Consultants, and Other Third-Party Service Providers.
              </span>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents ('third parties') who perform
              services for us or on our behalf and require access to such
              information to do that work. We have contracts in place with our
              third parties, which are designed to help safeguard your personal
              information. This means that they cannot do anything with your
              personal information unless we have instructed them to do it. They
              will also not share your personal information with any
              organisation apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct. The categories of third parties we may share personal
              information with are as follows:
            </p>
            <ul className="list-disc list-inside">
              <li>Ad Networks</li>
              <li>Affiliate Marketing Programs</li>
              <li>Cloud Computing Services</li>
              <li>Communication &amp; Collaboration Tools</li>
              <li>Data Analytics Services</li>
              <li>Data Storage Service Providers</li>
              <li>Finance &amp; Accounting Tools</li>
              <li>Government Entities</li>
              <li>Order Fulfilment Service Providers</li>
              <li>Payment Processors</li>
              <li>Performance Monitoring Tools</li>
              <li>Product Engineering &amp; Design Tools</li>
              <li>Retargeting Platforms</li>
              <li>Sales &amp; Marketing Tools</li>
              <li>Social Networks</li>
              <li>User Account Registration &amp; Authentication Services</li>
              <li>Testing Tools</li>
              <li>Website Hosting Service Providers</li>
            </ul>
            <p className="my-4">
              We also may need to share your personal information in the
              following situations:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>
              <li>
                When we use Google Analytics. We may share your information with
                Google Analytics to track and analyse the use of the Services.
                The Google Analytics Advertising Features that we may use
                include: Google Analytics Demographics and Interests Reporting,
                Remarketing with Google Analytics and Google Display Network
                Impressions Reporting. To opt out of being tracked by Google
                Analytics across the Services, visit{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . You can opt out of Google Analytics Advertising Features
                through Ads Settings and Ad Settings for mobile apps. Other opt
                out means include{" "}
                <a href="http://optout.networkadvertising.org/">
                  http://optout.networkadvertising.org/
                </a>{" "}
                and{" "}
                <a href="http://www.networkadvertising.org/mobile-choice">
                  http://www.networkadvertising.org/mobile-choice
                </a>
                . For more information on the privacy practices of Google,
                please visit the{" "}
                <a href="https://policies.google.com/privacy">
                  Google Privacy &amp; Terms page
                </a>
                .
              </li>
              <li>
                When we use Google Maps Platform APIs. We may share your
                information with certain Google Maps Platform APIs (e.g. Google
                Maps API, Places API).
              </li>
              <li>
                Other Users. When you share personal information (for example,
                by posting comments, contributions, or other content to the
                Services) or otherwise interact with public areas of the
                Services, such personal information may be viewed by all users
                and may be publicly made available outside the Services in
                perpetuity. Similarly, other users will be able to view
                descriptions of your activity, communicate with you within our
                Services, and view your profile.
              </li>
            </ul>
          </section>
          <section aria-labelledby="what-is-our-stance-on-third-party-websites">
            <h2 className="text-xl mt-8">
              5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We are not
              responsible for the safety of any information that you share with
              third parties that we may link to or who advertise on our
              Services, but are not affiliated with, our Services.
            </p>
            <p className="my-4">
              The Services may link to third-party websites, online services, or
              mobile applications and/or contain advertisements from third
              parties that are not affiliated with us and which may link to
              other websites, services, or applications. Accordingly, we do not
              make any guarantee regarding any such third parties, and we will
              not be liable for any loss or damage caused by the use of such
              third-party websites, services, or applications. The inclusion of
              a link towards a third-party website, service, or application does
              not imply an endorsement by us. We cannot guarantee the safety and
              privacy of data you provide to any third parties. Any data
              collected by third parties is not covered by this privacy notice.
              We are not responsible for the content or privacy and security
              practices and policies of any third parties, including other
              websites, services, or applications that may be linked to or from
              the Services. You should review the policies of such third parties
              and contact them directly to respond to your questions.
            </p>
          </section>
          <section aria-labelledby="do-we-use-cookies-and-other-tracking-technologies">
            <h2 className="text-xl mt-8">
              6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We may use cookies
              and other tracking technologies to collect and store your
              information.
            </p>
            <p className="my-4">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>
          <section aria-labelledby="is-your-information-transferred-internationally">
            <h2 className="text-xl mt-8">
              7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We may transfer,
              store, and process your information in countries other than your
              own.
            </p>
            <p className="my-4">
              Our servers are located in the United States. If you are accessing
              our Services from outside the United States, please be aware that
              your information may be transferred to, stored, and processed by
              us in our facilities and by those third parties with whom we may
              share your personal information (see 'WHEN AND WITH WHOM DO WE
              SHARE YOUR PERSONAL INFORMATION?' above), in the United States,
              and other countries.
            </p>
            <p className="my-4">
              If you are a resident in the European Economic Area (EEA), United
              Kingdom (UK), or Switzerland, then these countries may not
              necessarily have data protection laws or other similar laws as
              comprehensive as those in your country. However, we will take all
              necessary measures to protect your personal information in
              accordance with this privacy notice and applicable law.
            </p>
            <p className="my-4">
              <span>European Commission's Standard Contractual Clauses:</span>
            </p>
            <p className="my-4">
              We have implemented measures to protect your personal information,
              including by using the European Commission's Standard Contractual
              Clauses for transfers of personal information between our group
              companies and between us and our third- party providers. These
              clauses require all recipients to protect all personal information
              that they process originating from the EEA or UK in accordance
              with European data protection laws and regulations. Our Standard
              Contractual Clauses can be provided upon request. We have
              implemented similar appropriate safeguards with our third-party
              service providers and partners and further details can be provided
              upon request.
            </p>
          </section>
          <section aria-labelledby="how-long-do-we-keep-your-information">
            <h2 className="text-xl mt-8">
              8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We keep your
              information for as long as necessary to fulfil the purposes
              outlined in this privacy notice unless otherwise required by law.
            </p>
            <p className="my-4">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements).
            </p>
            <p className="my-4">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </section>
          <section aria-labelledby="how-do-we-keep-your-information-safe">
            <h2 className="text-xl mt-8">
              9. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We aim to protect
              your personal information through a system of organisational and
              technical security measures.
            </p>
            <p className="my-4">
              We have implemented appropriate and reasonable technical and
              organisational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>
          <section aria-labelledby="do-we-collect-information-from-minors">
            <h2 className="text-xl mt-8">
              10. DO WE COLLECT INFORMATION FROM MINORS?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> We do not knowingly
              collect data from or market to children under 18 years of age.
            </p>
            <p className="my-4">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at hello@sagelabs.tech.
            </p>
          </section>
          <section aria-labelledby="what-are-your-privacy-rights">
            <h2 className="text-xl mt-8">11. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> In some regions, such
              as the European Economic Area (EEA), United Kingdom (UK),
              Switzerland, and Canada, you have rights that allow you greater
              access to and control over your personal information. You may
              review, change, or terminate your account at any time.
            </p>
            <p className="my-4">
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
            </p>
            <p className="my-4">
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>
            <p className="my-4">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your Member State data protection authority
              or UK data protection authority.
            </p>
            <p className="my-4">
              If you are located in Switzerland, you may contact the Federal
              Data Protection and Information Commissioner.
            </p>
            <p className="my-4">
              <span className="font-bold">Withdrawing your consent:</span>
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section
              'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
            </p>
            <p className="my-4">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <p className="my-4">
              <span className="font-bold">
                Opting out of marketing and promotional communications:
              </span>
              You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send, or by contacting us using the details
              provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?' below. Yo u will then be removed from the marketing
              lists. However, we may still communicate with you — for example,
              to send you service-related messages that are necessary for the
              administration and use of your account, to respond to service
              requests, or for other non-marketing purposes.
            </p>
            <p className="my-4">
              If you have questions or comments about your privacy rights, you
              may email us at hello@sagelabs.tech.
            </p>
          </section>
          <section aria-labelledby="controls-for-do-not-track-features">
            <h2 className="text-xl mt-8">
              12. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <p className="my-4">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ('DNT') feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognising and
              implementing DNT signals has been finalised. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </section>
          <section aria-labelledby="do-united-states-residents-have-specific-privacy-rights">
            <h2 className="text-xl mt-8">
              13. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> If you are a resident
              of California, Colorado, Connecticut, Utah or Virginia, you are
              granted specific rights regarding access to your personal
              information.
            </p>
            <p className="my-4 font-bold">
              What categories of personal information do we collect?
            </p>
            <p className="my-4">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <div className="overflow-x-auto">
              <table className="table-auto border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Category</th>
                    <th className="px-4 py-2">Examples</th>
                    <th className="px-4 py-2">Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2">A. Identifiers</td>
                    <td className="px-4 py-2">
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      B. Personal information as defined in the California
                      Customer Records statute
                    </td>
                    <td className="px-4 py-2">
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </td>
                    <td className="px-4 py-2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      C. Protected classification characteristics under state or
                      federal law
                    </td>
                    <td className="px-4 py-2">Gender and date of birth</td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">D. Commercial information</td>
                    <td className="px-4 py-2">
                      Transaction information, purchase history, financial
                      details, and payment information
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">E. Biometric information</td>
                    <td className="px-4 py-2">Fingerprints and voiceprints</td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      F. Internet or other similar network activity
                    </td>
                    <td className="px-4 py-2">
                      Browsing history, search history, online behaviour,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">G. Geolocation data</td>
                    <td className="px-4 py-2">Device location</td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      H. Audio, electronic, visual, thermal, olfactory, or
                      similar information
                    </td>
                    <td className="px-4 py-2">
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      I. Professional or employment-related information
                    </td>
                    <td className="px-4 py-2">
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">J. Education Information</td>
                    <td className="px-4 py-2">
                      Student records and directory information
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      K. Inferences drawn from collected personal information
                    </td>
                    <td className="px-4 py-2">
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2">
                      L. Sensitive personal Information
                    </td>
                    <td className="px-4 py-2">&nbsp;</td>
                    <td className="px-4 py-2">NO</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="my-4">
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul className="list-disc list-inside">
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests;</li>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p className="my-4 font-bold">
              How do we use and share your personal information?
            </p>
            <p className="my-4">
              Learn about how we use your personal information in the 'HOW DO WE
              PROCESS YOUR INFORMATION?' section.
            </p>
            <p className="my-4">
              We collect and share your personal information through:
            </p>
            <ul className="list-disc list-inside">
              <li>Targeting cookies/Marketing cookies</li>
              <li>Social media cookies</li>
              <li>Beacons/Pixels/Tags</li>
              <li>Click redirects</li>
              <li>
                Social media plugins: We may use social media features, such as
                a 'Like' button, and widgets, such as a 'Share' button, in our
                Services. Such features may process your Internet Protocol (IP)
                address and track which page you are visiting on our website. We
                may place a cookie to enable the feature to work correctly. If
                you are logged in on a certain social media platform and you
                interact with a widget or button belonging to that social media
                platform, this information may be recorded to your profile of
                such social media platform. To avoid this, you should log out
                from that social media platform before accessing or using the
                Services. Social media features and widgets may be hosted by a
                third party or hosted directly on our Services. Your
                interactions with these features are governed by the privacy
                notices of the companies that provide them. By clicking on one
                of these buttons, you agree to the use of this plugin and
                consequently the transfer of personal information to the
                corresponding social media service. We have no control over the
                essence and extent of these transmitted data or their additional
                processing.
              </li>
            </ul>
            <p className="my-4 font-bold">
              Will your information be shared with anyone else?
            </p>
            <p className="my-4">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information in the section, 'WHEN AND WITH WHOM DO WE SHARE YOUR
              PERSONAL INFORMATION?'
            </p>
            <p className="my-4">
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be 'selling' of your personal information.
            </p>
            <p className="my-4">
              We have not sold or shared any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months. We have disclosed the following categories of
              personal information to third parties for a business or commercial
              purpose in the preceding twelve (12) months:
            </p>
            <p className="my-4">
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
            </p>
            <h3 className="text-lg my-4 font-semibold">California Residents</h3>
            <p className="my-4">
              California Civil Code Section 1798.83, also known as the 'Shine
              The Light' law permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p className="my-4">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems
              (e.g. backups, etc.).
            </p>
            <h4 className=" text-base my-4 font-bold underline">
              CCPA Privacy Notice
            </h4>
            <p className="my-4">
              This section applies only to California residents. Under the
              California Consumer Privacy Act (CCPA), you have the rights listed
              below.
            </p>
            <p className="my-4">
              The California Code of Regulations defines a 'residents' as:
            </p>
            <p className="my-1">
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </p>
            <p className="my-1">
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </p>
            <p className="my-4">
              All other individuals are defined as 'non-residents'.
            </p>
            <p className="my-4">
              If this definition of 'resident' applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <h4 className="my-4 font-bold">
              Your rights with respect to your personal data
            </h4>
            <h5 className="my-4 underline">
              Right to request deletion of the data — Request to delete
            </h5>
            <p className="my-4">
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </p>
            <h5 className="my-4 underline">
              Right to be informed — Request to know
            </h5>
            <p className="my-4">
              <span>
                Depending on the circumstances, you have a right to know:
              </span>
            </p>
            <ul className="list-disc list-inside">
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell or share personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold, shared, or
                disclosed for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information
                was sold, shared, or disclosed for a business purpose;
              </li>
              <li>
                the business or commercial purpose for collecting, selling, or
                sharing personal information; and
              </li>
              <li>
                the specific pieces of personal information we collected about
                you.
              </li>
            </ul>
            <p className="my-4">
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </p>

            <h5 className="my-4 underline">
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </h5>

            <p className="my-4">
              We will not discriminate against you if you exercise your privacy
              rights.
            </p>

            <h5 className="my-4 underline">
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </h5>

            <p className="my-4">
              We do not process consumer's sensitive personal information.
            </p>

            <h5 className="my-4 underline">Verification process</h5>

            <p className="my-4">
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </p>

            <p className="my-4">
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </p>

            <h5 className="my-4 underline">Other privacy rights</h5>

            <ul className="list-disc list-inside">
              <li>
                You may object to the processing of your personal information.
              </li>
              <li>
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </li>
              <li>
                You can designate an authorised agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorised agent that does not submit proof that they have been
                validly authorised to act on your behalf in accordance with the
                CCPA.
              </li>
              <li>
                You may request to opt out from future selling or sharing of
                your personal information to third parties. Upon receiving an
                opt-out request, we will act upon the request as soon as
                feasibly possible, but no later than fifteen (15) days from the
                date of the request submission.
              </li>
            </ul>

            <p className="my-4">
              To exercise these rights, you can contact us by email at
              hello@sagelabs.tech, or by referring to the contact details at the
              bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </p>

            <h3 className="text-lg my-4 font-semibold">Colorado Residents</h3>

            <p className="my-4">
              This section applies only to Colorado residents. Under the
              Colorado Privacy Act (CPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </p>

            <ul className="list-disc list-inside">
              <li>
                Right to be informed whether or not we are processing your
                personal data
              </li>
              <li>Right to access your personal data</li>
              <li>Right to correct inaccuracies in your personal data</li>
              <li>Right to request deletion of your personal data</li>
              <li>
                Right to obtain a copy of the personal data you previously
                shared with us
              </li>
              <li>
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects ('profiling')
              </li>
            </ul>

            <p className="§">
              To submit a request to exercise these rights described above,
              please email hello@sagelabs.tech.
            </p>

            <p className="my-4">
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at hello@sagelabs.tech.
              Within forty-five (45) days of receipt of an appeal, we will
              inform you in writing of any action taken or not taken in response
              to the appeal, including a written explanation of the reasons for
              the decisions.
            </p>

            <h3 className="text-lg my-4 font-semibold">
              Connecticut Residents
            </h3>

            <p className="my-4">
              This section applies only to Connecticut residents. Under the
              Connecticut Data Privacy Act (CTDPA), you have the rights listed
              below. However, these rights are not absolute, and in certain
              cases, we may decline your request as permitted by law.
            </p>

            <ul className="list-disc list-inside">
              <li>
                Right to be informed whether or not we are processing your
                personal data
              </li>
              <li>Right to access your personal data</li>
              <li>Right to correct inaccuracies in your personal data</li>
              <li>Right to request deletion of your personal data</li>
              <li>
                Right to obtain a copy of the personal data you previously
                shared with us
              </li>
              <li>
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects ('profiling')
              </li>
            </ul>

            <p className="my-4">
              To submit a request to exercise these rights described above,
              please email hello@sagelabs.tech.
            </p>

            <p className="my-4">
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at hello@sagelabs.tech.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions.
            </p>

            <h3 className="text-lg my-4 font-semibold">Utah Residents</h3>

            <p className="my-4">
              This section applies only to Utah residents. Under the Utah
              Consumer Privacy Act (UCPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </p>

            <ul className="list-disc list-inside">
              <li>
                Right to be informed whether or not we are processing your
                personal data
              </li>
              <li>Right to access your personal data</li>
              <li>Right to request deletion of your personal data</li>
              <li>
                Right to obtain a copy of the personal data you previously
                shared with us
              </li>
              <li>
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising or the sale of personal data
              </li>
            </ul>

            <p className="my-4">
              To submit a request to exercise these rights described above,
              please email hello@sagelabs.tech.
            </p>

            <h3 className="text-lg my-4 font-semibold">Virginia Residents</h3>

            <p className="my-4">
              Under the Virginia Consumer Data Protection Act (VCDPA):
            </p>

            <p className="my-4">
              'Consumer' means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </p>

            <p className="my-4">
              'Personal data' means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              'Personal data' does not include de-identified data or publicly
              available information.
            </p>

            <p className="my-4">
              'Sale of personal data' means the exchange of personal data for
              monetary consideration.
            </p>

            <p className="my-4">
              If this definition of 'consumer' applies to you, we must adhere to
              certain rights and obligations regarding your personal data.
            </p>

            <h5 className="my-4 underline">
              Your rights with respect to your personal data
            </h5>

            <ul className="list-disc list-inside">
              <li>
                Right to be informed whether or not we are processing your
                personal data
              </li>
              <li>Right to access your personal data</li>
              <li>Right to correct inaccuracies in your personal data</li>
              <li>Right to request deletion of your personal data</li>
              <li>
                Right to obtain a copy of the personal data you previously
                shared with us
              </li>
              <li>
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects ('profiling')
              </li>
            </ul>

            <h5 className="my-4 underline">
              Exercise your rights provided under the Virginia VCDPA
            </h5>

            <p className="my-4">
              You may contact us by email at hello@sagelabs.tech.
            </p>

            <p className="my-4">
              If you are using an authorised agent to exercise your rights, we
              may deny a request if the authorised agent does not submit proof
              that they have been validly authorised to act on your behalf.
            </p>

            <h5 className="my-4 underline">Verification process</h5>

            <p className="my-4">
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer's request. If you submit
              the request through an authorised agent, we may need to collect
              additional information to verify your identity before processing
              your request.
            </p>

            <p className="my-4">
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.
            </p>

            <h5 className="my-4 underline">Right to appeal</h5>

            <p className="my-4">
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at hello@sagelabs.tech.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal is denied, you may contact the Attorney
              General to submit a complaint.
            </p>
          </section>

          <section aria-labelledby="do-other-regions-have-specific-privacy-rights">
            <h2 className="text-xl mt-8">
              14. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>

            <p className="italic my-4">
              <span className="font-bold">In Short:</span> You may have
              additional rights based on the country you reside in.
            </p>

            <h3 className="text-lg my-4">Australia, New Zealand</h3>

            <p className="my-4">
              We collect and process your personal information under the
              obligations and conditions set by Australia's Privacy Act 1988 and
              New Zealand's Privacy Act 2020 (Privacy Act).
            </p>
            <p className="my-4">
              This privacy notice satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </p>
            <p className="my-4">
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </p>
            <ul className="list-disc list-inside">
              <li>offer you the products or services that you want</li>
              <li>respond to or help with your requests</li>
            </ul>
            <p className="my-4">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us using the contact details provided in the section
              'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?'
            </p>
            <p className="my-4">
              If you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the Office of the
              Australian Information Commissioner and a breach of New Zealand's
              Privacy Principles to the Office of New Zealand Privacy
              Commissioner.
            </p>
            <h3 className="text-lg">Republic of South Africa</h3>
            <p className="my-4">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?'
            </p>
            <p className="my-4">
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </p>
            <p className="my-4">
              <span>The Information Regulator (South Africa)</span>
              <br />
              <span>General enquiries: enquiries@inforegulator.org.za</span>
              <br />
              <span>General enquiries: enquiries@inforegulator.org.za</span>
              <br />
              <span>
                Complaints (complete POPIA/PAIA form 5):
                PAIAComplaints@inforegulator.org.za &amp;
                POPIAComplaints@inforegulator.org.za
              </span>
            </p>
          </section>

          <section aria-labelledby="do-we-make-updates-to-this-notice">
            <h2 className="text-xl mt-8">
              15. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <p className="italic my-4">
              <span className="font-bold">In Short:</span> Yes, we will update
              this notice as necessary to stay compliant with relevant laws.
            </p>
            <p className="my-4">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated 'Revised' date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </section>

          <section aria-labelledby="how-can-you-contact-us-about-this-notice">
            <h2 className="text-xl mt-8">
              16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <p className="my-4">
              If you have questions or comments about this notice, you may email
              us at hello@sagelabs.tech.
            </p>
          </section>

          <section aria-labelledby="how-can-you-review-update-or-delete-the-data-we-collect-from-you">
            <h2 className="text-xl mt-8">
              17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h2>
            <p className="my-4">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please email
              hello@sagelabs.tech.
            </p>
          </section>
        </article>
      </main>
    </>
  );
};

export default PrivacyPolicy;
